/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { InventoryAsset } from '../../../types';
import { getNestedValue } from '../../../utils/helpers';
import { DetailViewCard } from '../../Compounds/CardWithTitle';
import { DetailRow } from '../../Compounds/DetailsDrawer/DetailRow';
import { TicketDetailsDisplay } from '../SupportTickets/TicketDetailsDisplay';
import { PermissibleRender } from '../../Compounds/PermissibleRender';
import { Permission } from '../../../store/GeneralStore';
import { useEffect, useState } from 'react';
import { getInventoryLoadingSubject } from '../../../store/Inventory';
import { AttachmentTable } from '../../Compounds/AttachmentTable';
import { fetchNotificationsByEntity, Notification } from '../../../store/Notifications';
import { InstallNotifications } from './InstallNotifications';
import { defaultFeatureFlags, FeatureFlags, getFeatureFlagSubject } from '../../../Api/useFeatureFlags';
import { ComponentSpinner } from '../../Compounds/Loading/ComponentSpinner';
import { DefaultInventoryGridColumnDefs, SupportNumberDefaults } from './inventoryGridColumnDefs';
import { DetailsComments } from '../../Compounds/DetailsComments/DetailsComments';
import { shouldSeeComments, shouldSeeSupplierContacts } from '../../../store/User';
import { getAccountName } from '../../../store/Accounts';
import { useAttachments } from '../../../Api/Attachments/useAttachments';

export interface UpstackInventoryDetailsProps {
  data: InventoryAsset;
}

export function UpstackInventoryDetails({ data }: UpstackInventoryDetailsProps) {
  const [inventoryLoading, setInventoryLoading] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(defaultFeatureFlags);
  const [page, setPage] = useState<number>(1);
  const [fieldList, setFieldList] = useState<{ colId: string; title: string }[]>([]);
  const { fetch } = useAttachments();

  useEffect(() => {
    const flags = getFeatureFlagSubject().subscribe((flags) => setFeatureFlags(flags));
    const inventoryLoadingSub = getInventoryLoadingSubject().subscribe((loading: boolean) => {
      setInventoryLoading(loading);
    });

    return () => {
      if (flags) flags.unsubscribe();
      if (inventoryLoadingSub) inventoryLoadingSub.unsubscribe();
    };
  }, []);

  const getValue = (key: string) => {
    if (JSON.stringify(data) === '{}') return ''; // TODO: temporary fix
    if (key === 'product') return `${data?.product?.family} - ${data?.product?.name}`;
    if (key === 'UPSTACK Managed') return 'Internal';
    if (key.includes('.')) return getNestedValue(data, key);
    if (key === 'account_id' && data?.account_id) return getAccountName(data?.account_id);

    const val = data[key as keyof InventoryAsset] as string;
    return val || '';
  };

  useEffect(() => {
    const columns = [...DefaultInventoryGridColumnDefs];
    if (featureFlags.support_contact && shouldSeeSupplierContacts()) {
      columns.push(...SupportNumberDefaults);
    }
    setFieldList(
      columns
        .map((item) => {
          return {
            colId: item?.field,
            title: item?.field === 'UPSTACK Managed' ? 'Record Source' : item?.headerName || ''
          };
        })
        .filter((field) => !['id', 'detailCTA', 'sfId'].includes(field.colId) && getValue(field.colId) !== '')
        .sort((a, b) => {
          return a.colId === 'UPSTACK Managed' ? -1 : b.colId === 'UPSTACK Managed' ? 1 : 0;
        })
    );
  }, [featureFlags]);

  const getNotifications = async () => {
    const notify = await fetchNotificationsByEntity(data.id, page, notifications);
    if (notify) setNotifications(notify);
  };

  useEffect(() => {
    if (data.id) getNotifications();
  }, [data.id, page]);

  const fetchMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const fetchAttachments = () => {
    if (data?.id) {
      fetch(data?.id);
    }
  };

  return (
    <>
      {!!notifications.length && featureFlags.notifications && (
        <InstallNotifications
          filteredNotifications={notifications}
          fetchMore={fetchMore}
        />
      )}
      <DetailViewCard
        collapsed={false}
        enableCollapse
        key="inventory"
        title="Details">
        <div className="overflow-auto h-[30vh]">
          <>
            {inventoryLoading && (
              <div className="mt-32">
                <ComponentSpinner />
              </div>
            )}
            {!inventoryLoading &&
              fieldList.map((field) => (
                <DetailRow
                  key={field.colId}
                  colId={field.colId}
                  title={field.title}
                  value={getValue(field.colId) || ''}
                />
              ))}
          </>
        </div>
      </DetailViewCard>
      <PermissibleRender requiredPermissions={[Permission.VIEW_TICKETS_ON_INVENTORY]}>
        <TicketDetailsDisplay
          data={data}
          onTicketsChanged={async () => fetchAttachments()}
        />
      </PermissibleRender>
      {data.id && <AttachmentTable id={data.id} />}
      {featureFlags.comments && shouldSeeComments() && data?.id && (
        <DetailsComments
          id={data.id}
          entityType="Inventory"
          entityAccountId={data.account_id || ''}
        />
      )}
    </>
  );
}
