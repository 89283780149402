import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { InventoryAsset } from '../../../types';
import { PageLayout } from '../../PageLayout';
import { PageCard } from '../../Compounds/CardWithTitle';
import { DashboardWidgets } from '../../Compounds/DashboardWidgets/DashboardWidgets';
import { DetailsDrawer } from '../../Compounds/DetailsDrawer';
import { getMetaData } from '../../Compounds/DetailsDrawer/detailsDrawersHelpers';
import { getInventoryLoadingSubject, getInventorySubject, useInventory } from '../../../store/Inventory';
import {
  GridPreferences,
  PrefKey,
  UserPreferencesContent,
  getCustomViewSubject,
  getUserPreferencesSubject,
  shouldSeeSupplierContacts
} from '../../../store/User';
import { FeatureFlags, defaultFeatureFlags, getFeatureFlagSubject } from '../../../Api/useFeatureFlags';
import { CustomizeDashboardDrawer } from '../../Compounds/CustomizeDashboardDrawer';
import {
  contentWithProductFilters,
  getColumnsByProduct,
  getTabData
} from '../../Compounds/UpstackDataGrid/inventoryUtils';
import { UpstackDataGrid } from '../../Compounds/UpstackDataGrid/UpstackDataGrid';
import { getGridColumns } from '../../Compounds/UpstackDataGrid/helpers';
import { GridSingleSelectColDef } from '@mui/x-data-grid-pro';
import { PermissibleRender } from '../../Compounds/PermissibleRender';
import { Permission } from '../../../store/GeneralStore';
import { Icon } from '../../Atoms/Icon';
import { TextSpan } from '../../Atoms/Text';
import { useProductsStore } from '../../../store/Product';
import { useOutsideInventorySchemasStore } from '../../../store/OutsideInventorySchema';
import { Menu as CustomViewTabs } from './../../Compounds/CustomTableViews/Menu';
import { combineLatest } from 'rxjs';
import { Button, Tab, Tabs, useTheme } from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ViewsModal } from '../../Compounds/CustomTableViews/ViewsModal';
import { NewViewModal } from '../../Compounds/CustomTableViews/NewViewModal';
import { TabPanel } from '../../Atoms/TabPanel';
import { getRoute1Subject } from '../../../store/Route1';
import { SupportNumberDefaults } from './inventoryGridColumnDefs';

export interface TabProps {
  name: string;
  count: number;
  customView?: boolean;
}
export interface CategorizedData {
  [key: string]: InventoryAsset[];
}

export const InventoryPage = () => {
  const page = 'Inventory';
  const [inventoryAssets, setInventoryAssets] = useState<InventoryAsset[]>([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabs, setTabs] = useState<TabProps[]>([]);
  const [allTabs, setAllTabs] = useState<TabProps[]>([]);
  const [customTabNames, setCustomTabNames] = useState<string[]>([]);
  const [categorizedData, setCategorizedData] = useState<CategorizedData>({});
  const [inventoryLoading, setInventoryLoading] = useState<boolean>(false);
  const [columns, setColumns] = useState<GridSingleSelectColDef[]>();
  const [selectedCustomView, setSelectedCustomView] = useState<string>('');
  const [search] = useSearchParams();
  const detailId = search.get('detailId');
  const { fetchInventory } = useInventory();
  const { fetchProducts } = useProductsStore();
  const { fetchOutsideInventorySchemas } = useOutsideInventorySchemasStore();
  const [userPreferences, setUserPreferences] = useState<UserPreferencesContent>();
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(defaultFeatureFlags);
  const [open, setOpen] = useState<boolean>(false);
  const [createOpen, setCreateOpen] = useState<boolean>(false);
  const [triggerSave, setTriggerSave] = useState<boolean>(false);
  const state = { prevpath: location.pathname };
  const theme = useTheme();

  useEffect(() => {
    const dataSubscription = combineLatest({
      inventory: getInventorySubject(),
      settings: getUserPreferencesSubject(),
      inventoryLoading: getInventoryLoadingSubject(),
      flags: getFeatureFlagSubject(),
      customTabNames: getCustomViewSubject()
    }).subscribe(({ inventory, settings, inventoryLoading, flags, customTabNames }) => {
      // Flags
      setFeatureFlags(flags);

      // Inventory Assets
      setInventoryAssets(inventory);
      const { tabs, categorizedData } = getTabData(inventory);
      setTabs(tabs);

      setCategorizedData(categorizedData);
      const selectedTabName = tabs[selectedTab]?.name || 'All';
      const columns = getColumnsByProduct(selectedTabName);
      if (flags.support_contact && shouldSeeSupplierContacts() && selectedTabName == 'All') {
        columns.push(...SupportNumberDefaults);
      }
      setColumns(
        getGridColumns(
          (categorizedData as CategorizedData)[selectedTabName],
          columns,
          `inventory_grid_${selectedTabName}`
        )
      );

      // Inventory Loading
      setInventoryLoading(inventoryLoading);

      // User Settings
      setUserPreferences(
        contentWithProductFilters(
          settings.content,
          tabs.map((t) => t.name),
          [...new Set(inventory.map((i) => i.productDisplay || ''))]
        )
      );
      setCustomTabNames(customTabNames);
    });
    /**
     * @TODO REMOVE AFTER ROUTE1 migration
     * Keeping this as a separate subscription than the above data subscription for ease of cleanup
     */
    const route1Sub = getRoute1Subject().subscribe((enabled) => {
      if (enabled) {
        fetchInventory(true);
        fetchProducts();
      }
    });

    fetchInventory();
    fetchProducts();
    fetchOutsideInventorySchemas();

    return () => {
      if (dataSubscription) dataSubscription.unsubscribe();
      if (route1Sub) route1Sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (userPreferences) {
      let customTabs: TabProps[] = [];

      if (featureFlags.my_views) {
        customTabs = customTabNames.map((t) => ({ name: t, count: 0, customView: true }));
      }

      if (selectedCustomView && !customTabNames.includes(selectedCustomView)) {
        setSelectedCustomView('');
        handleTabChange(0, 'All');
      }

      setAllTabs(tabs.length ? tabs.concat(customTabs) : []);
    }
  }, [userPreferences, tabs, tabs.length, customTabNames.length]);

  useEffect(() => {
    if (tabs?.length) handleTabChange(0, tabs?.[0]?.name);
  }, [tabs?.length]);

  const tableCardActions = () => {
    return (
      <PermissibleRender requiredPermissions={[Permission.CREATE_OUTSIDE_INVENTORY]}>
        <Link
          role="link"
          title="Add Inventory"
          className="flex space-x-2 items-center"
          data-cy="new-outside-inventory"
          state={state}
          to="/add-inventory">
          <Icon
            type="plus"
            className="p-2"
          />
          <TextSpan
            color="indigo"
            size="sm14">
            Add Inventory
          </TextSpan>
        </Link>
      </PermissibleRender>
    );
  };

  const handleTabChange = (index: number, name: string, customTab?: boolean) => {
    let customIndex = 0;
    if (!customTab) setSelectedCustomView('');
    if (customTab) customIndex = allTabs.findIndex((t) => t.name === name);

    setSelectedTab(customTab ? customIndex : index);

    const columns = getColumnsByProduct(name);
    if (featureFlags.support_contact && shouldSeeSupplierContacts() && name == 'All') {
      columns.push(...SupportNumberDefaults);
    }
    setColumns(getGridColumns(categorizedData[name] || categorizedData['All'], columns, `inventory_grid_${name}`));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNewTabModalOpen = () => {
    setTriggerSave(false);
    setCreateOpen(true);
  };

  return (
    <PageLayout pageTitle={page}>
      <>
        <PageCard
          title="Dashboard"
          cardActions={<CustomizeDashboardDrawer title="Inventory" />}>
          <DashboardWidgets
            inventoryAssets={inventoryAssets}
            pageTitle={page}
            tickets={[]}
          />
        </PageCard>
        <PageCard
          childrenWrapperClass="min-h-80 max-w-[60vw] min-w-full"
          title={page}
          cardActions={tableCardActions()}>
          <>
            <Tabs
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'rgb(247 247 247)',
                borderBottom: '1px solid rgb(229 231 235)',
                borderTop: '1px solid rgb(229 231 235)',
                paddingRight: '28rem',
                maxWidth: '100%'
              }}
              TabIndicatorProps={{
                sx: {
                  backgroundColor: theme.palette.primary.main,
                  height: selectedTab <= tabs.length - 1 ? 2 : 0,
                  borderRadius: 1
                }
              }}
              value={selectedTab}
              variant="scrollable"
              scrollButtons="auto"
              onChange={(_e, index) => handleTabChange(index, allTabs?.[index]?.name)}>
              {tabs.map((tab, index) => (
                <Tab
                  sx={{ textTransform: 'none', fontSize: '.8rem', color: 'black' }}
                  key={index}
                  label={`${tab.name} (${categorizedData[tab.name].length})`}
                />
              ))}
            </Tabs>
            {tabs.length > 1 && featureFlags.my_views && (
              <div className="-mt-12 float-right inline-flex pr-4">
                <div className={`flex ${selectedCustomView ? 'border-b-2 border-indigo' : ''}`}>
                  <div
                    key="custom-views"
                    className="self-center">
                    <CustomViewTabs
                      setSelectedCustomView={setSelectedCustomView}
                      selectedCustomView={selectedCustomView}
                      customTabs={customTabNames}
                      handleTabChange={handleTabChange}
                    />
                  </div>
                </div>
                <div className="ml-3 self-center">
                  <Button
                    onClick={() => setTriggerSave(true)}
                    startIcon={<AddCircleIcon />}
                    sx={{
                      fontSize: '.8rem',
                      textTransform: 'none'
                    }}>
                    Save As Tab
                  </Button>
                </div>
                <div
                  className="ml-3 self-center"
                  key="add-custom-view">
                  <Button
                    onClick={() => setOpen(true)}
                    startIcon={<EditNoteIcon />}
                    sx={{
                      fontSize: '.8rem',
                      textTransform: 'none'
                    }}>
                    Edit Tabs
                  </Button>
                </div>
              </div>
            )}
            {allTabs.map((tab, index) => (
              <TabPanel
                key={`${index}`}
                index={index}
                value={selectedTab}>
                <UpstackDataGrid
                  dataCy="inventory-data-grid"
                  columns={columns}
                  gridSettings={(userPreferences?.[`inventory_grid_${tab.name}` as PrefKey] || {}) as GridPreferences}
                  handleNewTabModalOpen={handleNewTabModalOpen}
                  loadingData={inventoryLoading}
                  page={`inventory_grid_${tab.name}`}
                  pinnedColumns={{ left: ['details', 'UPSTACK Managed', 'account_id', 'sfId'] }}
                  rows={categorizedData['All']}
                  showSearch
                  title={`Inventory - ${tab.name}`}
                  triggerSave={triggerSave}
                />
              </TabPanel>
            ))}
          </>
        </PageCard>
        <div className="h-2"></div>
        <DetailsDrawer
          data={inventoryAssets?.find((ia: InventoryAsset) => ia?.id === detailId) || ({} as InventoryAsset)}
          showDrawer={!!detailId}
          link={`/inventory/${detailId}`}
          {...getMetaData('inventory')}
        />
      </>
      <ViewsModal
        open={open}
        handleClose={handleClose}
      />
      <NewViewModal
        open={createOpen}
        setOpen={setCreateOpen}
        sourceTab={allTabs[selectedTab]?.name || 'All'}
      />
    </PageLayout>
  );
};
